.box-argomenti {
  position: relative;
  display: block;
  min-height: 160px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: $medium;
  text-align: center;
  text-decoration: none;
  background-color: $white;
  border: solid 2px rgba($blue, 0.1);
  transition: border-color 0.5s;
  padding: 0 8px;
  padding-top: 32px;
  margin-top: 30px;

  &:hover, &:focus {
    color: $blue;
    border: solid 2px rgba($blue, 0.5);

    .box-argomenti__icon {
      color: $orange;
    }

    .box-argomenti__title {
      color: $blue;
    }
  }

  &__icon {
    color: $blue;
    transition: color 0.5s;
    max-height: 50px;

    @include media-breakpoint-down(sm) {
      max-width: 40px;
      max-height: 40px;
    }
  }

  &__title {
    display: block;
    font-size: 14px;
    line-height: 1.2;
    color: $black;
    word-break: break-word;
    transition: color 0.5s;
    margin-top: 16px;

    @include media-breakpoint-between(md, lg) {
      font-size: 16px;
    }
  }
}