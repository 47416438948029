.scroll-wrapper {

  @include media-breakpoint-up(lg) {
    position: relative;

    .col-left {
      position: sticky;
      top: 0;
      width: 50%;
      min-width: 50%;
      max-height: 100vh;
      padding-top: 20px;
      padding-right: 15px;
    }
    
    .col-right {
      width: 50%;
      min-width: 50%;
      padding-top: 20px;
      padding-left: 15px;
      margin-left: auto;
    }
  }
}