.box-dove-siamo {
  display: block;
  position: relative;
  font-size: 18px;
  color: $black;
  text-decoration: none;
  background-color: $white;
  border: solid 2px $blue-2;
  transition: color 0.5s, border-color 0.5s;
  padding: 20px;
  padding-left: 72px;
  margin-bottom: 16px;

  &--link {
    &:hover, &:focus {
      text-decoration: none;
      border: solid 2px rgba($blue, 0.5);
      
      .ico-svg {
        color: $orange;
      }
    }
  }

  .ico-svg {
    max-width: 40px;
    max-height: 44px;
    position: absolute;
    top: 24px;
    left: 20px;
    color: $blue;
    transition: color 0.5s;
  }

  &__label {
    display: block;
    font-size: 18px;
    line-height: 22px;
    font-weight: $medium;
    color: $black;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__value {
    display: block;
    font-size: 18px;
    line-height: 27px;
    font-weight: $regular;
    color: $black;
    text-decoration: none;
    margin-top: 2px;
  }
}