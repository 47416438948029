.section {
  padding: 30px 0;

  @include media-breakpoint-down(md) {
    padding: 15px 0;
  }

  &--double-padding {
    padding: 60px 0;

    @include media-breakpoint-down(md) {
      padding: 30px 0;
    }
  }

  &--white {
    background-color: $white;
  }

  @include media-breakpoint-down(sm) {
    &--bg-sm-blue {
      position: relative;
      background-color: $blue;
      margin-bottom: 30px;

      .section__title {
        color: $white;
      }

      .section__icon {
        position: absolute;
        top: -13%;
        left: -20%;
        right: 0;
        z-index: 0;
        max-width: none;
        max-height: none;
        color: $white;
        opacity: .1;

        &.top {
          top: 0;
        }
      }
    }
  }

  &--contacts {
    position: relative;
    overflow: hidden;
    background-color: rgba($blue, 0.1);

    .section__icon {
      z-index: 0;
      left: 75%;
      bottom: -10%;
      right: auto;
      max-width: 530px;
      max-height: 530px;
      color: $blue;

      @include media-breakpoint-only(md) {
        left: 65%;
      }

      @include media-breakpoint-down(sm) {
        left: 40%;
      }
    }
  }

  &--temi {
    padding-bottom: 60px;
    
    @include media-breakpoint-up(lg) {
      .col-lg {
        flex: 0 0 20%;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 40px;
    }
  }
  
  &__icon {
    position: absolute;
    right: 40%;
    bottom: -10%;
    z-index: 2;
    max-width: 90%;
    max-height: 90%;
    color: $white;
    opacity: 0.1;
  }

  @include media-breakpoint-up(md) {
    &--bg-square {
      position: relative;
      margin-top: 60px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 45%;
        height: 75%;
        background-color: $blue-1;
      }

      .section__title {
        color: $white;
      }

      .cta-link {
        margin-bottom: 30px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &__bg-box {
      position: relative;
      height: 100%;
      background-color: $blue;
      padding-right: 25px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 80%;
        z-index: 1;
        left: -15px;
        width: 18px;
        background-color: $blue;
      }

      .section__title {
        color: $white;
      }
    }
  }

  &__title {
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 22px;
    font-weight: $regular;
    margin-top: -20px;
    
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      font-weight: $regular;
    }
  }

  &__link {
    margin-bottom: 20px;
  }

  &__label {
    display: inline-block;
    font-size: 21px;
    line-height: 27px;
    font-weight: $medium;
    margin-right: 8px;
  }

  &__text-box {
    position: relative;
    z-index: 2;
    width: 390px;
    max-width: 100%;
    padding-left: 3px;
    padding-right: 25px;

    @media (min-width: 1600px) {
      width: 355px;
    }

    @include media-breakpoint-between(md, lg) {
      width: 325px;
    }
  }

  //Accordion smaprtphone
  @include media-breakpoint-down(sm) {
    &.js-accordion {

      &.open {
        .section__title {
          &::after {
            top: 13px;
            transform: rotate(-135deg);
          }
        }
        .section__content {
          max-height: 5000px;
        }
      }

      .section__title {
        position: relative;
        
        &::after {
          content: "";
          position: absolute;
          top: 1px;
          right: 5px;
          border: solid $black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          transform: rotate(45deg);
          padding: 8px;
        }
      }

      .section__content {
        max-height: 0;
        overflow: hidden;
        transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
      }
    }
  }
}