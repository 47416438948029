.box-contatti {
  display: block;
  position: relative;
  height: calc(100% - 30px);
  font-size: 16px;
  line-height: 1.2;
  font-weight: $regular;
  color: $black;
  text-decoration: none;
  background-color: $white;
  border: solid 2px rgba($blue, 0.1);
  transition: border-color 0.5s;
  padding: 20px;
  margin-bottom: 30px;

  &--small {
    margin-bottom: 20px;

    .box-contatti__link {
      margin-top: 8px;
      margin-left: 0;
    }

    .box-contatti__avatar {
      width: 72px;
      min-width: 72px;
      height: 72px;

      span {
        line-height: 72px;
      }
    }

    .box-contatti__img {
      width: 72px;
      min-width: 72px;
      height: 72px;
    }
  }

  &__avatar {
    width: 85px;
    min-width: 85px;
    height: 85px;
    background-color: $orange;
    border-radius: 50%;
    margin-right: 20px;

    span {
      display: inline-block;
      font-size: 30px;
      line-height: 85px;
      font-weight: $bold;
      color: $white;
    }
  }

  &__img {
    width: 85px;
    min-width: 85px;
    height: 85px;
    border-radius: 50%;
  }
  
  &__name {
    font-size: 18px;
    line-height: 1.2;
    font-weight: $bold;
    color: $black;
    margin-bottom: 6px;
  }

  &__referente {
    font-size: 14px;
    line-height: 1.2;
    color: rgba($black, 0.6);
    margin-top: 4px;
  }

  &__info {
    border-top: solid 2px rgba($blue, 0.1);
    padding-top: 20px;
    margin-top: 24px;
  }

  &__link {
    display: inline-block;
    font-size: 16px;
    line-height: 1.2;
    font-weight: $regular;
    color: $orange;
    text-decoration: none;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      span {
        color: $orange;
      }
    }

    .ico-svg {
      max-width: 19px;
      max-height: 19px;
      margin-right: 8px;
    }

    span {
      font-size: 16px;
      line-height: 1.2;
      font-weight: $regular;
      color: $black;
    }
  }
}