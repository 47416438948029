.webcontent-detail {
  padding-top: 30px;
  
  &--reserved {
    .webcontent-detail__main {
      padding-bottom: 0;
    }

    .col-reserved {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -50px;
        z-index: 998;
        height: 75%;
        background: $gray;
        background: linear-gradient(0deg, rgba($gray, .9) 0%, rgba($gray, .76) 50%, transparent 100%);
      }
    }

    .webcontent-detail__login {
      position: absolute;
      left: 50%;
      right: 0;
      bottom: 80px;
      z-index: 999;
      max-width: 350px;
      background-color: $white;
      box-shadow: 0 0 20px rgba($black, .1);
      transform: translateX(-50%);
      padding: 40px;
  
      .ico-svg {
        max-width: 67px;
        max-height: 77px;
        color: $blue;
      }
  
      &-title {
        font-size: 21px;
        font-weight: $bold;
        margin-top: 24px;
        margin-bottom: 24px;
      }
  
      &-desc {
        margin-bottom: 24px;
      }
    }
  }

  &__nav {
    padding-bottom: 1px;

    @media (min-height: 300px) {
      position: sticky;
      top: 24px;
    }
    
    ul {
      list-style: none;
      padding: 0;
      margin: 24px 0 40px 0;  

      li {
        display: block;
        font-size: 16px;
        line-height: 19px;
        font-weight: $regular;
        color: $black;

        a {
          display: block;
          font-size: 16px;
          line-height: 19px;
          font-weight: $regular;
          color: $black;
          text-decoration: none;
          border-left: solid 2px rgba($blue, 0.1);
          padding: 8px 16px;
  
          &.current {
            font-size: 16px;
            line-height: 19px;
            font-weight: $bold;
            color: $black;
            border-color: $blue;
          }
        }
      }
    }
  }

  &__box {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 40px;

    &-title {
      font-size: 21px;
      line-height: 25px;
      font-weight: $bold;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      line-height: 1.2;
      margin-bottom: 0;
    }

    .webcontent-detail__calendar {
      color: $blue-1;
      font-weight: $medium;
      margin-bottom: 24px;

      .ico-svg {
        color: $blue-1;
      }
      
      &--active {
        .ico-svg {
          color: $blue-1;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__main {
      padding-bottom: 150px;
    }
  }

  &__abstract {
    margin-bottom: 32px;
  }

  &__category {
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    font-weight: $medium;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include media-breakpoint-only(sm) {
      font-size: 12px;
    }

    span {
      display: inline-block;
      background-color: $blue;
      padding: 11px 12px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  
  &__category_group {
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    font-weight: $bold;
    color: $blue-1;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include media-breakpoint-only(sm) {
      font-size: 12px;
    }
	
	span {
      display: inline-block;
      background-color: $white;
      padding: 9px 12px;
      margin-right: 8px;
      margin-bottom: 8px;
	  border: 2px solid;
    }
  }

  &__date {
    font-size: 18px;
    line-height: 22px;
    font-weight: $regular;
    margin-bottom: 16px;
  }

  &__img {
    width: 100%;
    margin-bottom: 40px;
  }

  &__logo {
    width: 100%;
    border: solid 2px rgba($blue, 0.1);
    margin-bottom: 40px;
  }

  &__info {
    margin-bottom: 24px;

    &-title {
      color: $blue-1;
      font-weight: $medium;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .ico-svg {
      max-width: 26px;
      max-height: 26px;
      color: $blue-1;
      margin-right: 12px;
    }
  }

  &__calendar-info {
    color: $white;
    background-color: $blue-1;
    padding: 15px 0;
    margin-bottom: 30px;
  }

  &__tag {
    display: inline-block;
    font-size: 16px;
    line-height: 1.2;
    text-decoration: none;
    margin-right: 8px;

    @include media-breakpoint-between(md, lg) {
      font-size: 14px;
    }
  }

  &__attivita {
    display: inline-block;
    font-size: 14px;
    line-height: 1.2;
    color: $white;
    background-color: $blue-1;
    padding: 4px;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  &__settore {
    display: inline-block;
    font-size: 16px;
    line-height: 1.2;
    text-decoration: none;
    margin-right: 8px;

    @include media-breakpoint-between(md, lg) {
      font-size: 14px;
    }
  }

  &__calendar {
    font-size: 14px;
    line-height: 21px;
    font-weight: $light;
    color: $white;

    .remove {
      display: none;
    }

    .ico-svg {
      max-width: 18px;
      max-height: 21px;
      color: $white;
      margin-right: 8px;
    }

    &--active {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
      .ico-svg {
        color: $white;
      }
    }
  }

  &__section {
    padding-top: 12px;
    padding-bottom: 48px;
  
    ul:not(.slick-dots) {
      list-style-type: none;
      
      li {
        position: relative;
        margin-bottom: 10px;

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 13px;
          left: -20px;
          width: 5px;
          height: 5px;
          background-color: $blue;
          border-radius: 50%;
        }
      }
    }
  }

  &__section-title {
    margin-bottom: 20px;
  }

  &__attachment {
    font-size: 16px;
    line-height: 1.2;
    color: $black;
    text-decoration: none;
    background-color: $white;
    border: solid 2px rgba($blue, 0.1);
    transition: color 0.5s, border-color 0.5s;
    padding: 24px 16px;
    margin-top: 20px;

    .ico-svg {
      max-width: 30px;
      max-height: 35px;
      color: $orange;
      transition: color 0.5s;
      margin-right: 8px;
    }

    &:hover, &:focus {
      border-color: $blue;

      .ico-svg {
        color: $blue;
      }
    }
  }
  
  .bookmark__btn {
    .ico-svg {
      max-width: 19px;
      max-height: 25px;
    }
  }
}