.unindustria-footer {
  position: relative;
  padding-top: 46px;
  padding-bottom: 20px;

  @include media-breakpoint-down(sm) {
    padding-bottom: 24px;
  }

  &__bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background-image: linear-gradient(180deg, $blue-3, rgba($blue, 0.9));
    }
  }
  
  &__content {
    position: relative;
    z-index: 2;
    color: $white;
  }

  &__section {
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__info {
    font-size: 12px;
    line-height: 20px;
    font-weight: $medium;
    margin-bottom: 24px;
  }

  &__social {
    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }

    .social-link {
      display: inline-block;
      color: $white;
      text-decoration: none;
      margin-left: 12px;

      &:first-child {
        margin-left: 0;
      }

      &:hover, &:focus {
        color: $orange;
      }

      .ico-svg {
        max-width: 24px;
        max-height: 24px;
      }
    }
  }

  &__logo-qualita {
    width: 90px;
    margin-bottom: 24px;
  }

  &__menu {
    border-top: solid 1px rgba($white, 0.8);
    padding-top: 24px;

    @include media-breakpoint-down(sm) {
      border-top: 0;
      padding-top: 16px;
      margin-top: 24px;
    }
  }

  &__social {
    padding-bottom: 20px;
  }

  &__link {
    font-size: 14px;
    line-height: 20px;
    font-weight: $regular;
    color: $white;
    text-decoration: none;
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__engineered {
    font-size: 12px;
    line-height: 1;
    font-weight: $regular;
    color: $white;
    text-decoration: none;
    text-align: right;
    margin-top: 10px;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
    
    a {
      color: $white;
      text-decoration: none;

      &:hover {
        color: $orange;
      }
    }
  }
}