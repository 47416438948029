.unindustria-subscribe {
  margin-top: 24px;

  &__link {
    font-size: 14px;
    line-height: 1;
    font-weight: $medium;
    color: $blue-1;
    text-decoration: none;
    background-color: $white;
    border-radius: 6px;
    padding: 0 12px;

    span {
      display: inline-block;
      margin-left: 12px;

      @include media-breakpoint-down(sm) {
        margin-left: 8px;
      }
    }

    .ico-svg {
      width: 12px;
      min-width: 12px;
      max-width: 12px;
      max-height: 12px;
    }

    &:hover, &:focus {
      color: $blue-1;
      text-decoration: none;
    }
  }

  &__info {
    position: relative;
    color: $white;
    margin-left: 12px;
    
    @include media-breakpoint-down(sm) {
      margin-left: 8px;
    }

    span {
      display: inline-block;
    }

    .ico-svg {
      max-width: 14px;
      max-height: 14px;
    }

    &:hover, &:focus {
      .unindustria-subscribe__tooltip {
        display: block;
      }
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    bottom: 30px;
    width: 284px;
    font-size: 13px;
    line-height: 1.2;
    color: $black;
    background-color: $white;
    padding: 15px;
    margin-left: -134px;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -5px;
      width: 10px;
      height: 10px;
      background-color: $white;
      transform: rotate(-135deg);
      margin-left: -5px;
    }
  }
}