.unindustria-riferimenti {
  margin-top: 60px;

  @include media-breakpoint-down(md) {
    margin-top: 40px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  &__link {
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    font-weight: $regular;
    color: $white;
    text-decoration: none;
    margin-right: 16px;
    
    &:hover, &:focus {
      color: $white;
      text-decoration: underline;
    }
  }

  &__avatar {
    width: 42px;
    height: 42px;
    background-color: $orange;
    border-radius: 50%;
    margin-right: -12px;

    span {
      display: inline-block;
      font-size: 27px;
      line-height: 40px;
      font-weight: $bold;
      color: $white;
      border: solid 1px $white;
	}
	
	span.no-image {
	  font-size: 18px;
	}
  }

  &__img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: solid 1px $white;
  }
}