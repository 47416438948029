.cta-link {
  position: relative;
  display: inline-block;
  height: 33px;
  font-size: 14px;
  line-height: 33px;
  font-weight: $medium;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  background-color: $orange-2;
  border: 0;
  border-radius: 0;
  padding-right: 33px;

  &:hover, &:focus, &--selected {
    background-color: $orange;
    color: $white;
    text-decoration: none;
  }

  &:before, &:after {
    content: "";
    position: absolute;
  }

  &:before {
    width: 16px;
    height: 1px;
    top: 50%;
    right: 10px;
    background-color: $white;
    margin-top: -1px;
  }

  &:after {
    border: solid $white;
    display: inline-block;
    border-width: 0 1px 1px 0;
    right: 10px;
    top: 50%;
    transform: rotate(315deg);
    padding: 4px;
    margin-top: -5px;
  }

  span {
    display: inline-block;
    height: 33px;
    font-size: 14px;
    line-height: 33px;
    padding: 0 10px;
    background-color: $orange;
  }

  &--no-arrow {
    background-color: transparent;
    border: solid 1px $white;
    padding: 0 15px;

    &:hover, &:focus, &--selected {
      background-color: transparent;
    }

    &:before, &:after {
      content: none;
    }
  }

  &--icon {
    &:before, &:after {
      content: none;
    }

    .ico-svg {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      max-width: 14px;
      max-height: 14px;
    }
    
  }
}

.btn-icon {
  display: flex;
  width: 38px;
  height: 38px;
  color: $orange;
  background-color: transparent;
  border: solid 1px $orange;
  border-radius: 50%;
  padding: 7px;
  margin-left: 12px;

  &:hover, &:focus {
    color: $orange;
    text-decoration: none;
    background-color: transparent;
  }

  &--active {
    color: $white;
    background-color: $orange;

    &:hover, &:focus {
      color: $white;
      background-color: $orange;
    }
  }

  .ico-svg {
    max-width: none;
    max-height: none;
  }
}

.tag-link {
  display: inline-block;
  font-weight: $medium;
  text-decoration: none;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

/* responsive video */
.iframe-responsive {
  position: relative;
  z-index: 2;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* video 16:9 */
  margin-bottom: 24px;
  
  &.ratio-21by9 {
    padding-bottom: 42.857143%; /* 21:9 */
  }
  &.ratio-4by3 {
    padding-bottom: 75%; /* 4:3 */
  }
  &.ratio-1by1 {
    padding-bottom: 100%; /* 1:1 */
  }
  &.ratio-1by2 {
    padding-bottom: 200%; /* 1:2 */
  }
  
  iframe, object, embed  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.h1, h1, h1 a,
.h2, h2, h2 a,
.h3, h3, h3 a,
.h4, h4, h4 a,
.h5, h5, h5 a,
.h6, h6, h6 a {
  font-weight: $bold;
  line-height: 1.2;
  color: $black;
  margin-top: 0;
  margin-bottom: 0;
}
h1, h1 a {
  font-size: 40px;

  @include media-breakpoint-between(md, lg) {
    font-size: 30px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}
h2, h2 a {
  font-size: 36px;

  @include media-breakpoint-between(md, lg) {
    font-size: 28px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 26px;
  }
}
h3, h3 a {
  font-size: 26px;

  @include media-breakpoint-between(md, lg) {
    font-size: 24px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
}
h4, h4 a {
  font-size: 21px;
}
h5, h5 a {
  font-size: 18px;
}
h6, h6 a {
  font-size: 18px;
}
p {
  font-size: 18px;
  line-height: 27px;
  font-weight: $regular;
  color: $black;
  margin-bottom: 20px;
}
a {
  color: $orange;
  text-decoration: underline;
  transition: color 0.5s;
  
  @include media-breakpoint-up(lg) {
    &:hover, &:focus {
      color: $orange;
      text-decoration: none;
    }
  }
}
b, .bold,
strong, .strong {
  font-weight: $bold;
}
i, em {
  font-style: italic;
}
ul, ol {
  padding: 0;
  margin: 20px 0 20px 20px;
}
nav ul,
nav ol {
  margin: 0;
}
figure {
  margin: 0;
}
img {
  max-width: 100%;
}

// Container custom extra-large
.container-extra {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
// Boostrap grid
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.small-gutters {
  margin-right: -4px;
  margin-left: -4px;

  > .col,
  > [class*="col-"] {
    padding-right: 4px;
    padding-left: 4px;
  }
}