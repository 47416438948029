.unindustria-pagination {
  font-size: 16px;
  line-height: 1;
  color: $black;
  margin-top: 30px;

  &__sort {
    position: relative;
    font-size: 16px;
    line-height: 1;
    margin-right: 30px;
  }

  &__sort-label {
    color: $black;
  }

  &__btn {
    position: relative;
    font-size: 16px;
    line-height: 1;
    color: $orange;
    background-color: transparent;
    padding-right: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      right: 2px;
      display: inline-block;
      border: solid $black;
      border-width: 0 2px 2px 0;
      padding: 3px;
      transform: rotate(45deg);
    }
  }

  &__menu {
    &.open {
      .unindustria-pagination__options {
        display: block;
      }
    }
  }

  &__options {
    display: none;
    width: 230px;
    position: absolute;
    left: 0;
    z-index: 99;
    font-size: 18px;
    line-height: 30px;
    color: $black;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 40px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $white;
      margin-left: -5px;

      @include media-breakpoint-down(xs) {
        left: 30px;
      }
    }

    > div {
      background-color: $white;
      box-shadow: 0 4px 4px 0 rgba($blue-3, 0.1), 0 8px 16px 0 rgba($blue-3, 0.25);
      padding: 30px 20px;
      margin-top: 15px;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    margin-left: 60px;

    @include media-breakpoint-down(md) {
      margin-top: 15px;
      margin-left: 0;
    }
  }

  &__item {
    font-weight: $bold;
    list-style-type: none;
  }

  &__link {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: $black;
    text-align: center;
    text-decoration: none;
    margin: 0 2px;

    &:hover, &:focus {
      background-color: $white;

      &.unindustria-pagination__link--arrow:before {
        border-color: $orange;
      }
    }

    &--active {
      color: $white;
      background-color: $orange;

      &:hover, &:focus {
        color: $white;
        cursor: text;
        background-color: $orange;
      }
    }

    &--arrow {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 13px;
        left: 13px;
        border: solid $black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
      }
    }

    &--prev {
      &::before {
        transform: rotate(135deg);
      }
    }

    &--next {
      &::before {
        transform: rotate(-45deg);
      }
    }
  }
}