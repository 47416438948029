.unindustria-hamburger {
  flex: 0 0 32px;
  width: 32px;
  height: 27px;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform .5s ease-in-out;
  margin-left: 32px;
  
  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $white;
    filter: alpha(opacity=1);
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    
    &:nth-child(1) {
      top: 5px;
    }
    
    &:nth-child(2), &:nth-child(3) {
      top: 13px;
      left: auto;
      right: 0;
    }
    
    &:nth-child(4) {
      top: 21px;
    }
  }
  
  &.open {
    
    span {
      &:nth-child(1) {
        width: 0%;
        left: 50%;
      }
      
      &:nth-child(2) {
        left: 0;
        right: auto;
        transform: rotate(45deg);
        max-width: none;
      }
      
      &:nth-child(3) {
        left: 0;
        right: auto;
        transform: rotate(-45deg);
        max-width: none;
      }
      
      &:nth-child(4) {
        width: 0%;
        left: 50%;
      }
    }
  }
}