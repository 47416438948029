.unindustria-search-grid {

  &__header {
    font-size: 16px;
    color: $black;
    border-bottom: solid 2px rgba($blue, 0.1);
    padding-bottom: 12px;

    .ico-svg {
      color: $black;
      max-width: 10px;
      max-height: 10px;
      margin-left: 16px;
    }
  }

  &__results {
    padding-top: 30px;
  }

  &__badge {
    padding-bottom: 15px;

    a, button {
      display: inline-block;
      height: 29px;
      font-size: 14px;
      line-height: 25px;
      color: $blue-1;
      font-weight: $regular;
      text-transform: uppercase;
      background-color: transparent;
      border-radius: 16px;
      border: solid 2px $blue-1;
      padding: 0 10px;
      margin-right: 15px;
      margin-bottom: 15px;

      .ico-svg {
        min-width: 15px;
        max-width: 15px;
        max-height: 15px;
        min-height: 15px;
        color: $blue-1;
        cursor: pointer;
        margin-left: 3px;
        margin-bottom: 2px;
      }
    }
  }

  &__month {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 1.2;
    color: $orange;
    font-weight: $bold;
    text-decoration: none;
    margin-top: 32px;

    .ico-svg {
      max-width: 28px;
      max-height: 26px;
    }
  }
}