.box-partner {
  width: 20%;
  text-align: center;
  margin: 30px 0;

  @include media-breakpoint-down(md) {
    width: 33.3333%;
  }

  @include media-breakpoint-down(xs) {
    width: 50%;
  }
  
  &__link {
    display: inline-block;
  }

  &__img {
    display: inline-block;
    max-height: 50px;
    line-height: 0;
  }
}