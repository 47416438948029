.unindustria-sort {
  position: relative;
  margin-left: 30px;

  @include media-breakpoint-down(xs) {
    margin-left: 15px;
  }

  &.open {
    .unindustria-sort__submenu {
      display: block;
    }
    .unindustria-sort__btn {
      .ico-svg {
        transform: rotate(180deg);
      }
    }
  }

  &__btn {
    position: relative;
    background-color: transparent;
  }

  &__label {
    span {
      color: $orange;
      font-weight: $semibold;
    }
  }

  &__submenu {
    display: none;
    color: $black;
    width: 230px;
    position: absolute;
    right: 0;
    z-index: 99;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      right: 40px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $white;
      margin-left: -5px;

      @include media-breakpoint-down(xs) {
        right: 30px;
      }
    }

    > div {
      background-color: $white;
      box-shadow: 0 4px 4px 0 rgba($blue-3, 0.1), 0 8px 16px 0 rgba($blue-3, 0.25);
      padding: 30px 20px;
      margin-top: 15px;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__item {
    list-style-type: none;
    padding: 5px 0;
  }

  &__link {
    color: $orange;
    text-decoration: none;
    background-color: transparent;

    &:hover {
      color: $orange;
      text-decoration: underline;
    }

    &--active {
      font-weight: $semibold;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .ico-svg {
    color: $black;
    max-width: 10px;
    max-height: 10px;
    margin-left: 16px;
  }
}