.box-servizi {
  $margin-y: 15px;

  display: block;
  position: relative;
  height: 100%;
  line-height: 1.5;
  font-weight: $medium;
  color: $black;
  text-decoration: none;
  margin: $margin-y 0;

  &:hover, &:focus {
    text-decoration: none;
    
    .box-servizi__content {
      border: solid 2px rgba($blue, 0.5);
    }
    
    .box-servizi__icon {
      color: $orange;
    }
  }

  &__content {
    position: relative;
    display: block;
    height: calc(100% - #{$margin-y * 2});
    position: relative;
    background-color: $white;
    border: solid 2px rgba($blue, 0.1);
    transition: border-color 0.5s;
    padding: 20px;
    padding-left: 84px;
  }

  &__category {
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    font-weight: $medium;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 16px;

    @include media-breakpoint-only(sm) {
      font-size: 12px;
    }

    span {
      display: inline-block;
      background-color: $blue;
      padding: 7px 8px;
      margin-right: 8px;
      margin-bottom: 4px;
    }
  }
  
  &__title {
    display: block;
    font-size: 21px;
    line-height: 1.2;
    font-weight: $bold;
    color: $black;
    margin-bottom: 8px;

    @include media-breakpoint-between(md, lg) {
      font-size: 18px;
    }
  }

  &__abstract {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    font-weight: $regular;
    color: $black;
    padding-right: 8px;

    p {
      font-size: 14px;
      line-height: 1.5;
      font-weight: $medium;
      color: $black;
      margin-bottom: 0;
    }
  }

  &__icon {
    position: absolute;
    top: 20px;
    left: 20px;
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    color: $blue;
    transition: color 0.5s;
  }
}