.box-photo-lightbox {
  overflow: hidden;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    &:hover {
      .box-photo-lightbox__link {
        transform: scale(1.1);
      }
      .box-photo-lightbox__title {
        bottom: 18px;
      }
    }
  }
  
  &__link {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 75%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .6s ease-in-out 0s;
  }
  
  &__title {
    display: block;
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: -100%;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    background-color: rgba($black, 0.8);
    transition: all .6s ease-in-out 0s;
    padding: 10px 20px;
  }
}