.slider {
  padding-bottom: 40px;

  img {
    line-height: 0;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;

    .slick-slide {
      height: auto;

      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }

  .slick-dots {
    display: block;
    list-style: none;
    position: absolute;
    bottom: -15px;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      cursor: pointer;
      padding: 0;
      margin-left: 4px;
      margin-right: 4px;

      button {
        display: block;
        width: 16px;
        height: 16px;
        color: transparent;
        background: transparent;
        border: 0;
        cursor: pointer;
        outline: none;
        padding: 0;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 8px;
          text-align: center;
          background-color: $blue;
          border-radius: 50%;
          -webkit-font-smoothing: antialiased;
          transform: translate(-50%, -50%);
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: $orange;
          }
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: 30px;
    z-index: 10;
    width: 40px;
    height: 40px;
    text-indent: -9999em;
    background-color: transparent;
    border: 0;
    padding: 0;

    &:hover, &:focus {
      outline: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: 13px;
      right: 10px;
      border: solid $orange;
      display: inline-block;
      border-width: 0 1px 1px 0;
      padding: 6px;
      transform: rotate(135deg);
    }
    
    &.slick-prev {
      left: 9%;

      @include media-breakpoint-only(xl) {
        left: 5%;
      }

      @media (min-width: 1400px) {
        left: 12.5%;
      }
    }
    
    &.slick-next {
      left: 9%;
      margin-left: 90px;

      @include media-breakpoint-only(xl) {
        left: 5%;
      }

      @media (min-width: 1400px) {
        left: 12.5%;
      }

      &:after {
        transform: rotate(315deg);
        right: 16px;
      }
    }
  }

  &.slider-hero {
    line-height: normal;
    padding-top: 60px;
    padding-bottom: 90px;

    @include media-breakpoint-between(md, lg) {
      padding-top: 40px;
      padding-bottom: 60px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 24px;
      padding-bottom: 56px;
    }

    .hero {
      display: none;

      &:first-child {
        display: block;
      }
    }

    &.slick-initialized {
      .hero {
        display: block;
      }
    }

    .counter {
      display: inline-block;
      width: 50px;
      position: absolute;
      bottom: 30px;
      left: 9%;
      font-size: 18px;
      line-height: 42px;
      font-weight: $light;
      color: $black;
      text-align: center;
      margin-left: 40px;

      @include media-breakpoint-only(xl) {
        left: 5%;
      }

      @media (min-width: 1400px) {
        left: 12.5%;
      }

      @include media-breakpoint-down(md) {
        left: 5px;
        bottom: 4px;
        color: $white;
      }
    }
    
    @include media-breakpoint-down(md) {
      .slick-arrow {
        left: 5px;
        bottom: 4px;
      }
    }

    .slider-hero__text {
      padding-top: 36px;
      padding-left: 58px;
      padding-right: 8px;

      @include media-breakpoint-up(xl) {
        max-width: 82%;
      }

      @include media-breakpoint-down(md) {
        padding-top: 24px;
        padding-left: 15px;
        padding-right: 15px;
      }

      @include media-breakpoint-between(md, lg) {
        padding-top: 0;
        padding-left: 32px;
      }
    }
  }

  &.slider-appuntamenti {
    padding-bottom: 60px;
    margin: 0 -15px;
    margin-top: 24px;

    @include media-breakpoint-up(xl) {
      &.num-slide-3 {
        .slick-track {
          transform: translate3d(0px, 0px, 0px) !important;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &.num-slide-1, &.num-slide-2 {
        .slick-track {
          transform: translate3d(0px, 0px, 0px) !important;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 20px;

      .slick-arrow {
        bottom: 0;
      }
    }

    .slick-list {
      padding-left: 0 !important;
    }

    .slick-arrow {
      &.slick-prev {
        left: 5px;
      }

      &.slick-next {
        left: 0;
        margin-left: 60px;
      }
    }

    .unindustria-card {
      height: 100%;
      margin: 0 15px;

      &__content {
        height: calc(100% - 30px);
        margin-bottom: 30px;
      }
    }
  }

  &.slider-progetti {
    padding-bottom: 60px;
    margin: 0 -15px;
    margin-top: 24px;

    @include media-breakpoint-up(xl) {
      &.num-slide-2 {
        .slick-track {
          transform: translate3d(0px, 0px, 0px) !important;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &.num-slide-1 {
        .slick-track {
          transform: translate3d(0px, 0px, 0px) !important;
        }
      }
    }

    .slick-list {
      padding-left: 0 !important;
    }

    .slick-arrow {
      &.slick-prev {
        left: 5px;
      }

      &.slick-next {
        left: 0;
        margin-left: 60px;
      }
    }

    .unindustria-card {
      height: 100%;
      margin: 0 15px;

      &__content {
        height: calc(100% - 30px);
        margin-bottom: 30px;
      }
    }
  }
  
  &.slider-news-imprese {
    padding-bottom: 0;
    margin: 0 -15px;

    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }

    .slick-arrow {
      &.slick-prev {
        left: 5px;
      }

      &.slick-next {
        left: 0;
        margin-left: 60px;
      }
    }

    .unindustria-card {
      height: 100%;
      margin: 0 15px;

      &__content {
        height: calc(100% - 30px);
        margin-bottom: 30px;
      }
    }
  }

  &.slider-photogallery-detail {
    .slick-arrow {
      top: 50%;
      margin-top: -40px;

      &::after {
        border-width: 0 5px 5px 0;
        padding: 10px;
      }

      &.slick-prev {
        left: 25px;
      }

      &.slick-next {
        left: auto;
        right: 25px;
        margin-left: 0;
      }
    }

    .slick-dots {
      bottom: 15px;
    }
  }
  
  &.slider-guide {
    padding-bottom: 0;
    margin: 0 -15px;

    .slick-arrow {
      &.slick-prev {
        left: 5px;
      }

      &.slick-next {
        left: 0;
        margin-left: 60px;
      }
    }

    .unindustria-card {
      height: 100%;
      margin: 0 15px;

      &__content {
        height: calc(100% - 30px);
        margin-bottom: 30px;
      }
    }
  }
}
