.header-container {
  position: relative;

  @include media-breakpoint-down(md) {
    .col-nav {
      position: static;
    }
  }

  &.open {
    .header-container__bg-megamenu {
      background-color: $blue-1;
      z-index: 7;

      &::before {
        background-image: linear-gradient(270deg, $white, rgba($white, 0));
      }
    }

    .unindustria-navigation {
      &__item {
        &--active:not(.open) {
          .unindustria-navigation__link {
            border-color: transparent;
          }
        }
      }
    }
  }

  &--fullwidth {
    .header-container__logo {
      .img-blue {
        display: none;
      }
      .img-white {
        display: inline-block;
      }
    }

    .header-container__bg-img {
      max-width: none;
    }
  }

  &__logo {
    display: inline-block;
    max-height: 60px;
    margin-top: 28px;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }

    .img-blue {
      display: inline-block;
      max-height: 60px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .img-white {
      display: none;
      max-height: 60px;

      @include media-breakpoint-down(md) {
        display: inline-block;
        max-height: 38px;
      }
    }
  }

  &__bg-img, &__bg-megamenu {
    width: 100%;
    max-width: calc(50% + 282px); // (1140 - 15) div 4
    min-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      max-width: calc(50% + 237px); // (960 - 15)  div 4
    }

    @include media-breakpoint-down(md) {
      max-width: none;
    }
  }

  &__bg-megamenu {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      width: 100%;
      height: 45px;
      background-image: linear-gradient(270deg, $blue-2, rgba($white, 0));
      opacity: 0.2;

      @include media-breakpoint-down(md) {
        content: none;
      }
    }
  }

  &__bg-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background-image: linear-gradient(180deg, $blue-3, rgba($blue, 0.9));
    }
  }

  &__header {
    position: relative;
    z-index: 1010;

    @include media-breakpoint-down(md) {
      padding-top: 20px;
    }
  }

  &__content {
    position: relative;
    z-index: 5;
  }

  &__search {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    min-height: 100vh;
    color: $white;
    background-color: $blue-1;
    padding-top: 60px;

    @include media-breakpoint-down(md) {
      padding-top: 24px;
    }

    &.open {
      display: block;
    }

    &-btn {
      color: $white;

      .ico-svg {
        max-width: 32px;
        max-height: 32px;
      }
    }

    &-title {
      font-size: 36px;
      line-height: 1.2;
      font-weight: $bold;
      margin-top: 40px;
      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }

    .search-form {
      background-color: $blue-1;
      border: 2px solid rgba($white, .7);

      &__text {
        color: $white;
        background-color: $blue-1;

        &::placeholder {
          color: rgba($white, .7);
        }
      }

      &__submit {
        color: $white;
      }
    }
  }
}