.box-organizzazione {
  padding-top: 30px;
  
  @include media-breakpoint-up(md) {
    text-align: center;
  }

  &__logo {
    max-height: 92px;
    margin-bottom: 56px;
  }

  &__title {
    display: inline-block;
    font-size: 21px;
    line-height: 1.2;
    font-weight: $bold;
    text-transform: uppercase;
    padding-bottom: 12px;
    border-bottom: solid 4px $blue-1;
  }

  &__wrapper {
    position: relative;
    margin-top: 32px;
    margin-bottom: 80px;
    
    @include media-breakpoint-up(md) {
      margin: 0 -15px;
      margin-top: 60px;
      margin-bottom: 60px;
    }

    &::before, &:after {
      content: "";
      position: absolute;
      display: block;
    }

    &::before {
      background-color: $blue-1;

      @include media-breakpoint-up(md) {
        left: 8.3%;
        right: 8.4%;
        height: 1px;
      }
      
      @include media-breakpoint-down(sm) {
        top: -32px;
        left: 23px;
        width: 1px;
        height: 32px;
      }
    }

    &::after {
      background-color: $blue-1;

      @include media-breakpoint-up(md) {
        top: -60px;
        left: 50%;
        width: 1px;
        height: 60px;
      }
      
      @include media-breakpoint-down(sm) {
        content: none;
      }
    }
  }
  
  &__col {
    position: relative;
    text-align: center;
      
    @include media-breakpoint-down(sm) {
      text-align: left;
      padding-left: 60px;
      padding-bottom: 24px;

      &:last-child {
        &::after {
          bottom: 91%;
        }
      }
    }

    &::before, &:after {
      content: "";
      position: absolute;
      display: block;
    }

    &::before {
      width: 12px;
      height: 12px;
      background-color: $blue-1;
      border-radius: 50%;
      transform: translate(-50%);

      @include media-breakpoint-down(sm) {
        top: 5%;
        left: 24px;
      }
    }

    &::after {
      background-color: $blue-1;
      width: 1px;
      top: 0;

      @include media-breakpoint-up(md) {
        height: 80px;
        left: calc(50% - 1px);
      }

      @include media-breakpoint-down(sm) {
        left: 23px;
        bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      width: calc(100% / 6);
      padding: 0 8px;
      padding-top: 132px;

      &::before {
        top: 80px;
        left: 50%;
      }
    }
  }

  &__link {
    display: inline-block;
  }

  &__progetti {
    min-height: 70px;
    font-size: 16px;
    line-height: 22px;
    font-weight: $bold;
    color: $black;
    border-bottom: solid 2px $blue-1;
    margin-bottom: 6px;

    @include media-breakpoint-only(md) {
      min-height: 90px;
    }

    @include media-breakpoint-down(sm) {
      min-height: auto;
      border-bottom: none;
      margin-bottom: 2;

      &::before {
        content: "";
        position: absolute;
        top: 5%;
        left: 24px;
        width: 30px;
        height: 1px;
        background-color: $blue-1;
        margin-top: 6px;
      }
    }
  }

  &__partner {
    display: inline-block;
    font-size: 16px;
    line-height: 22px;
    color: $blue-1;
    text-transform: uppercase;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
    }
  }

  &__consulenza {
    font-size: 16px;
    line-height: 22px;
    color: $black;

    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
    }
  }
}