.unindustria-imprese {
  height: calc(100% - 30px);
  margin-bottom: 30px;

  &:hover, &:focus {
    .unindustria-card__content {
      border: solid 2px rgba($blue, 0.5);
    }
  }

  &__content {
    position: relative;
    background-color: $white;
    border: solid 2px rgba($blue, 0.1);
    transition: border-color 0.5s;
    padding: 20px;
  }

  &__title {
    font-size: 21px;
    line-height: 26px;
    font-weight: $medium;
    color: $black;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__link {
    font-size: 21px;
    line-height: 26px;
    font-weight: $medium;
    color: $black;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 21px;
    }

    &:hover, &:focus {
      color: $blue;
    }
  }

  &__settore {
    font-size: 14px;
    line-height: 18px;
    font-weight: $medium;
    color: $orange;
    text-align: center;
    margin-top: auto;
  }
}