.search-form {
  background-color: $white;
  border: solid 2px rgba($blue, 0.1);
  padding-right: 20px;

  &--disabled {
    background-color: $gray;
  }

  &__text {
    font-size: 18px;
    line-height: 1.2;
    font-weight: $regular;
    color: $black;
    border: 0;
    padding: 15px 20px;

    &:disabled {
      background-color: $gray;
      cursor: not-allowed;
    }
  }

  &__submit {
    font-size: 18px;
    line-height: 1.2;
    font-weight: $regular;
    color: $black;

    .ico-svg {
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
    }
  }
}

.search-form-wrapper {
  background-color: $blue-1;
  padding-top: 8px;
  padding-bottom: 24px;
  margin-bottom: 30px;

  .search-form {
    background-color: $blue-1;
    border: 0;
    border-bottom: solid 2px rgba($white, .7);
    padding-right: 0;

    &__text {
      color: $white;
      background-color: $blue-1;
      padding-left: 0;

      &::placeholder {
        color: rgba($white, .7);
      }
    }
    
    &__submit {
      color: $white;
    }
  }
}