.topnav {
  height: 45px;
  
  @include media-breakpoint-down(md) {
    height: 100%;
  }

  &__list {
    font-size: 14px;
    line-height: 25px;
    color: $white;
    padding: 0;
    margin: 0;

    @include media-breakpoint-between(md, lg) {
      font-size: 13px;
    }
  }

  &__item {
    list-style-type: none;
    font-size: 14px;
    line-height: 25px;
    color: $white;
    margin-left: 32px;

    @include media-breakpoint-between(md, lg) {
      font-size: 13px;
    }

    @include media-breakpoint-down(md) {
      line-height: 1;
      margin-left: 24px;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 25px;
    color: $white;
    text-decoration: none;

    @include media-breakpoint-between(md, lg) {
      font-size: 13px;
    }

    @include media-breakpoint-down(md) {
      line-height: 1;
    }

    .ico-svg {
      max-width: 17px;
      max-height: 14px;
      margin-right: 8px;

      @include media-breakpoint-down(md) {
        max-width: 20px;
        max-height: 25px;
      }
    }

    &--active {
      color: $orange;
    }
  }

  &__avatar {
    width: 23px;
    height: 23px;
    font-size: 10px;
    color: $white;
    line-height: 22px;
    text-align: center;
    background-color: $blue-1;
    border: solid 1px $white;
    border-radius: 50%;
    margin-right: 8px;

    img {
      width: 23px;
      height: 23px;
      border-radius: 50%;
    }
  }

  .cta-link {
    height: 25px;
    line-height: 25px;
    text-transform: none;

    span {
      height: 25px;
      line-height: 25px; 
    }

    @include media-breakpoint-down(md) {
      background-color: transparent;

      .ico-svg {
        max-width: 20px;
        max-height: 25px;
      }
    }
  }
}