.box-area {
  border-bottom: solid 2px rgba($blue, 0.1);
  padding: 48px 0;

  &:first-child {
    border-top: solid 2px rgba($blue, 0.1);
  }

  &__title {
    font-size: 26px;
    line-height: 1.2;
    font-weight: $bold;
    margin-bottom: 24px;
  }

  &__description {
    font-size: 18px;
    line-height: 27px;
    font-weight: $regular;
    color: $black;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
    
    p {
      font-size: 18px;
      line-height: 27px;
      font-weight: $regular;
      color: $black;
      margin-bottom: 0;
    }
  }
}