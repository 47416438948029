.footer-nav {
  padding: 0;
  margin: 0;
  margin-bottom: 40px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0;

    &.open {
      .footer-nav__section {
        &::after {
          top: 18px;
          transform: rotate(-135deg);
        }
      }
      .footer-nav__submenu {
        max-height: 3500px;
      }
    }
  }

  &__section {
    font-size: 16px;
    line-height: 20px;
    font-weight: $bold;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      position: relative;
      cursor: pointer;
      padding: 10px 0;
      margin-bottom: 0;

      &::after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        display: inline-block;
        border: solid $white;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        padding: 6px;
      }
    }
  }

  &__item {
    list-style-type: none;

    @include media-breakpoint-down(sm) {
      border-bottom: solid 1px rgba($white, 0.3);
    }
  }

  &__submenu {
    .footer-nav__item {
      border-bottom: solid 1px rgba($white, 0.3);

      @include media-breakpoint-down(sm) {
        &:last-child {
          border-bottom: 0;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      max-height: 0;
      overflow: hidden;
      transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
    }
  }

  &__sublist {
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(sm) {
      padding-bottom: 24px;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    font-weight: $regular;
    color: $white;
    text-decoration: none;
    padding: 7px 0;
  }
}