.banner-wrapper {
	display: none;
	width: 100%;
}

@media (min-width: 979px) {
	.compliance-eu-cookie-banner {
		position: fixed;
		width: 100%;
		left: 0px;
		bottom: 0px;
		background: none repeat scroll 0% 0% #7D7D7D;
		font-size: 13px;
		font-weight: 700;
		text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.35);
		z-index: 99999;
		text-align: center;
		color: #FFF;	
		/*height: 55px;*/
		padding: 10px 10px 10px 10px;
	}
}

@media (max-width: 979px) {
	.compliance-eu-cookie-banner {
		position: fixed;
		width: 100%;
		left: 0px;
		bottom: 0px;
		background: none repeat scroll 0% 0% #7D7D7D;
		font-size: 13px;
		font-weight: 700;
		text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.35);
		z-index: 99999;
		text-align: center;
		color: #FFF;	
		/* height: 105px; */
		padding: 10px 10px 10px 10px;
	}
}

.accept-eu-cookie-banner {
	display: inline-block;
	color: #000 !important;
	text-decoration: none;
/* 	background: none repeat scroll 0% 0% #7DAF3B; */
	background: white;
	padding: 2px 8px 2px 8px;	
	border-radius: 3px;
	box-shadow: none;
	text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.35);
}

div {
	box-sizing: border-box;
}

@media (min-width: 992px) {
	.col-md-10, .span10 {
		width: 83.33333%;
		float:left;
	 }
}

@media (min-width: 992px) {
	.col-md-2, .span2 {
		width: 16.66667%;
		float:left;
	}
}

@media (min-width: 1200px) {
	.col-lg-11 {
		width: 91.66667%;
		float:left;
	}
}

@media (min-width: 1200px) {
	.col-lg-1 {
		width: 8.33333%;
		float:left;
	}
}

.message-eu-cookie-banner {
	padding: 5px;
}

a.info-eu-cookie-banner {
	color: #FFF !important;
	text-decoration: underline !important;
	padding: 5px;
}

.btn-close-banner {
	background: #FFF;
	color: #000;
	border-radius:3px;
	font-size: 16px;
}

.wrap-button:before{
	display: inline-block;
	height:100%;
	vertical-align:middle;
	content:'';
}