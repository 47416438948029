/*
* Unindustria
* Copyright 2021, Ariadne
* Author: Davide Pillitteri
* 01/04/2021
* Bootstrap v4.3.1
* https://getbootstrap.com/docs/4.3/getting-started/introduction/
*/


/* Import Modules and Variables
==================================================*/
@import 'modules/bootstrap';
@import 'modules/mixins';
@import 'modules/variables';


/* Import Plug-In
==================================================*/
@import '~slick-carousel/slick/slick.scss';
@import '~@staaky/fresco/dist/css/fresco.css';
@import '~flatpickr/dist/flatpickr.min.css';


/* Import Style
==================================================*/
@import 'partials/reset';
@import 'partials/liferay';

// .wrapper must be added to liferay #wrapper div inside portal_normal.ftl
// This is the most external container of a custom project, avoiding
// administration panel
.wrapper {
	font-family: $barlow;
  font-size: 18px;
  line-height: 27px;
  font-weight: $regular;
  color: $black;
  background-color: $gray;
	
	@import 'partials/styles';

	@import 'partials/header-container';
	@import 'partials/topnav';
	@import 'partials/navigation';
	@import 'partials/article-header';
	@import 'partials/unindustria-breadcrumb';

	@import 'partials/section';
	@import 'partials/scroll-wrapper';
	
	@import 'partials/unindustria-card';
	@import 'partials/unindustria-indice';
	@import 'partials/unindustria-sort';
	@import 'partials/unindustria-pagination';
	@import 'partials/unindustria-search-grid';
	@import 'partials/unindustria-modal';
	@import 'partials/unindustria-hamburger';
	@import 'partials/unindustria-subscribe';
	@import 'partials/unindustria-riferimenti';
	@import 'partials/unindustria-imprese';

	@import 'partials/hero';
	@import 'partials/slider';
	@import 'partials/faccette';
	@import 'partials/search-form';
	@import 'partials/webcontent-detail';
	@import 'partials/nav-child';
	@import 'partials/mypage';
	@import 'partials/bookmark';
	@import 'partials/form-login';
	@import 'partials/checkbox';
	@import 'partials/radio';
	@import 'partials/article-login';
	@import 'partials/cookie';
	
	@import 'partials/box-accordion';
	@import 'partials/box-argomenti';
	@import 'partials/box-partner';
	@import 'partials/box-banner';
	@import 'partials/box-contatti';
	@import 'partials/box-servizi';
	@import 'partials/box-photo-lightbox';
	@import 'partials/box-useful';
	@import 'partials/box-dove-siamo';
	@import 'partials/box-area';
	@import 'partials/box-organizzazione';

	@import 'partials/unindustria-footer';
	@import 'partials/footer-nav';
}