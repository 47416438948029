.mypage {
  
  &__badges {
    margin-top: 24px;
    margin-bottom: 40px;

    a, button {
      display: inline-block;
      height: 29px;
      font-size: 14px;
      line-height: 25px;
      color: $blue;
      font-weight: $regular;
      text-transform: uppercase;
      text-decoration: none;
      background-color: transparent;
      border-radius: 16px;
      border: solid 2px $blue;
      padding: 0 10px;
      margin-right: 8px;
      margin-bottom: 12px;

      .ico-svg {
        display: none;
        min-width: 15px;
        max-width: 15px;
        max-height: 15px;
        min-height: 15px;
        color: $blue;
        cursor: pointer;
        margin-right: 3px;
        margin-bottom: 2px;
      }

      &.active {
        color: $white;
        background-color: $blue;

        .ico-svg {
          display: inline-block;
          color: $white;
        }
      }
    }
  }

  &__box {
    background-color: $white;
    border-left: solid 2px rgba($blue, 0.1);
    border-right: solid 2px rgba($blue, 0.1);
    border-bottom: solid 2px rgba($blue, 0.1);
    padding: 26px;

    &:first-child {
      border-top: solid 2px rgba($blue, 0.1);
    }
    
    @include media-breakpoint-down(md) {
      &:last-child {
        margin-bottom: 24px;
      }
    }

    &-title {
      font-size: 21px;
      line-height: 25px;
      font-weight: $bold;
    }

    .ico-svg {
      max-width: 27px;
      max-height: 27px;
      color: $orange;
      margin-left: 8px;
    }
  }

  &__category {
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    font-weight: $medium;
    color: $white;
    text-transform: uppercase;
    background-color: $blue;
    padding: 6px 8px;
    margin-right: 8px;
    margin-bottom: 8px;

    @include media-breakpoint-only(sm) {
      font-size: 12px;
    }
  }

  &__avatar {
    position: relative;
    width: 130px;
    height: 130px;
    text-align: center;
    background-color: $blue-1;
    border-radius: 50%;
    margin: 0 auto 24px;

    span {
      font-size: 40px;
      line-height: 130px;
      font-weight: $medium;
      color: $white;
    }

    img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      width: 44px;
      height: 44px;
      color: $orange;
      background-color: $white;
      border: solid 1px $orange;
      border-radius: 50%;

      .ico-svg {
        max-width: none;
        max-height: none;
        padding: 10px;
        margin-left: 0;
      }
    }
  }

  &__logout {
    font-size: 14px;
    line-height: 20px;
    color: $black;
    text-decoration: none;
    margin-top: 16px;

    .ico-svg {
      max-width: 27px;
      max-height: 20px;
      color: $orange;
      margin-right: 4px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__scroll {
      position: relative;

      .col-left {
        width: 66.666667%;
        min-width: 66.666667%;
        padding-top: 20px;
        padding-right: 15px;
        margin-left: auto;
      }
      
      .col-right {
        position: sticky;
        top: 0;
        width: 33.333333%;
        min-width: 33.333333%;
        max-height: 100vh;
        padding-top: 20px;
        padding-left: 15px;
      }
    }
  }
}