.article-login {
  position: relative;
  max-width: 45%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 60px;

  @include media-breakpoint-down(md) {
    max-width: none;
    padding: 30px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: linear-gradient(180deg, $blue-3, rgba($blue, 0.9));
  }

  &__text {
    position: relative;
    z-index: 2;
  }

  &__title {
    font-size: 30px;
    line-height: 1.2;
    font-weight: $bold;
    color: $white;
    margin-bottom: 40px;
  }

  &__abstract {
    font-size: 18px;
    line-height: 1.2;
    color: $white;
    margin-bottom: 40px;

    p {
      font-size: 18px;
      line-height: 1.2;
      color: $white;
    }
  }
}