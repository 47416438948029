.radio {
  display: block;
  position: relative;
  user-select: none;
  font-size: 18px;
  line-height: 1.2;
  color: $black;
  cursor: pointer;
  padding-left: 30px;
  margin-bottom: 24px;
  margin-right: 32px;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  &__input {
    position: absolute;
    opacity: 0;
    
    &:checked {
      ~ .radio__mark {
        background-color: $white;
        border-color: $black;
        
        &::after {
          display: block;
        }
      }
    }
  }
  
  &__mark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $black;
    border-radius: 50%;
    
    &::after {
      display: none;
      content: "";
      position: absolute;
      top: 2px;
      right: 6px;
      width: 6px;
      height: 10px;
      border: solid $orange;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
    }
  }
}