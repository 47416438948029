.form-login {
  padding: 60px;

  @include media-breakpoint-down(md) {
    padding: 30px;
  }

  .modal-title {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 24px;
  }

  &__box {
    margin-bottom: 24px;
  }

  &__label {
    display: block;
    font-size: 18px;
    line-height: 1.2;
    color: $black;
  }

  &__input-text {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 1.2;
    color: $black;
    border: solid 1px $black;
    border-radius: 0;
    padding: 4px 8px;
  }

  &__link {
    font-size: 18px;
    line-height: 1.2;
    color: $orange;
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}