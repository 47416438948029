.unindustria-modal {

  .modal-content {
    border: solid 2px rgba($blue, 0.1);
    border-radius: 0;
  }
  
  .modal-header {
    height: auto;
    box-sizing: content-box;
    border-bottom: 0;
    padding: 15px;
  }

  .modal-title {
    font-size: 40px;
    line-height: 1.2;
    font-weight: $bold;
    color: $black;
  }

  .modal-close {
    color: $orange;

    .ico-svg {
      max-width: 40px;
      max-height: 40px;
    }
  }

  .modal-body {
    padding: 15px;
    padding-bottom: 30px;
  }

  .modal-category {
    display: inline-block;
    height: 29px;
    font-size: 14px;
    line-height: 25px;
    color: $blue;
    font-weight: $semibold;
    text-transform: uppercase;
    text-decoration: none;
    background-color: transparent;
    border: solid 2px $blue;
    padding: 0 10px;
    margin-right: 24px;
    margin-top: 24px;

    .ico-svg {
      display: none;
      min-width: 15px;
      max-width: 15px;
      max-height: 15px;
      min-height: 15px;
      color: $blue;
      cursor: pointer;
      margin-right: 3px;
      margin-bottom: 2px;
    }

    &.active {
      color: $white;
      background-color: $blue;

      .ico-svg {
        display: inline-block;
        color: $white;
      }
    }
  }

  .modal-footer {
    border-top: 0;
    border-radius: 0;
  }
}