.unindustria-breadcrumb {
  font-size: 14px;
  line-height: 32px;
  color: $white;
  padding-top: 16px;
  padding-bottom: 24px;
  
  &__list {
    line-height: 32px;
    padding: 0;
    margin: 0;
  }
  
  &__item {
    display: inline;
    list-style-type: none;
    font-size: 14px;
    line-height: 32px;
    color: $white;
  }
  
  &__link {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 32px;
    color: $white;
    text-decoration: none;
    margin-right: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -13px;
      display: inline-block;
      border: solid $white;
      border-width: 0 1px 1px 0;
      padding: 2px;
      transform: rotate(-45deg);
      margin-top: -1px;
    }
    
    @include media-breakpoint-down(sm) {
      padding-left: 12px;
      
      &::after {
        right: auto;
        left: 0;
        transform: rotate(135deg);
      }
    }
    
    &:not(span):hover {
      color: $white;
      text-decoration: underline;
      
      .ico-svg {
        color: $white;
      }
    }
  }
  
  &__current {
    font-size: 14px;
    line-height: 32px;
    font-weight: $bold;
    color: $white;
  }
}
