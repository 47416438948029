/* Fonts
==================================================*/
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;700&display=swap');
$barlow:        'Barlow', sans-serif;

$light:         300;
$regular:       400;
$medium:        500;
$semibold:      600;
$bold:          700;
$extrabold:     900;


/* Colors
==================================================*/
$white:         #fff;

$black:         #000;

$gray:          #f8fafb;
$gray-2:        #DEDEDE;

$blue:          #125095;
$blue-1:        #0770D8;
$blue-2:        #E1E9F0;
$blue-3:        #0A3552;

$orange:        #F78215;
$orange-2:      #E67710;