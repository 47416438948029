.box-banner {
  $margin-y: 15px;
  $img-size: 40%;

  display: block;
  position: relative;
  height: 100%;
  line-height: 1.5;
  font-weight: $medium;
  color: $black;
  text-decoration: none;
  margin: $margin-y 0;

  &:hover, &:focus {
    text-decoration: none;
    
    .box-banner__content {
      border: solid 2px rgba($blue, 0.5);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $img-size;
    height: calc(100% - #{$margin-y * 2});
    background-image: linear-gradient(180deg, $blue-3, $blue);
    opacity: .5;
  }

  &__content {
    display: block;
    height: calc(100% - #{$margin-y * 2});
    position: relative;
    background-color: $white;
    border: solid 2px rgba($blue, 0.1);
    transition: border-color 0.5s;
    padding: 27px 29px;
    margin-right: $img-size;
  }
  
  &__title {
    display: block;
    position: relative;
    font-size: 21px;
    line-height: 1.5;
    font-weight: $medium;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include media-breakpoint-between(md, lg) {
      font-size: 18px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 32px;
      height: 2px;
      background-color: $orange;
    }
  }

  &__abstract {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    font-weight: $medium;
    color: $black;

    @include media-breakpoint-between(md, lg) {
      font-size: 14px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      font-weight: $medium;
      color: $black;
      margin-bottom: 0;

      @include media-breakpoint-between(md, lg) {
        font-size: 14px;
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $img-size;
    height: calc(100% - #{$margin-y * 2});
    object-fit: cover;
  }
}