.unindustria {
	font-family: $barlow;
  font-size: 18px;
  line-height: 27px;
  font-weight: $regular;
  color: $black;
  background-color: $gray;
}

.header-container {
  &__header {
    .portlet {
      position: static;
    }
  }
}

.portlet {
  margin-bottom: 0;

  .portlet-content,
  .portlet-minimized .portlet-content-container {
    padding: 0;
  }

  .portlet-content {
    &-editable {
      border-width: 0;
    }
  }
}

.pager {
  font-family: $barlow;
  font-weight: $bold;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 30px;

  li {
    display: inline-block;

    >a, >span {
      position: relative;
      display: inline-block;
      font-family: $barlow;
      font-weight: $bold;
      font-size: 14px;
      border-radius: 0;
      padding: 5px 14px 2px 14px;

      &:before {
        content: "";
        position: absolute;
        top: 16px;
        width: 14px;
        height: 2px;
        background-color: $black;
      }

      &:after {
        content: "";
        position: absolute;
        top: 12px;
        border: solid $black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
      }
    }

    &:first-child {
      >a, a>span {
        padding-left: 32px;
        margin-right: 8px;

        @include media-breakpoint-down(xs) {
          margin-right: 4px;
        }
        
        &:before {
          left: 10px;
        }

        &:after {
          left: 10px;
          transform: rotate(135deg);
        }
      }
    }

    &:last-child {
      >a, a>span {
        padding-right: 32px;
        margin-left: 8px;

        @include media-breakpoint-down(xs) {
          margin-left: 4px;
        }
        
        &:before {
          right: 10px;
        }

        &:after {
          right: 10px;
          transform: rotate(-45deg);
        }
      }
    }

    &.disabled {
      >a, >span {
        &:before {
          background-color: #6c757d;
        }
  
        &:after {
          border-color: #6c757d;
        }
      }
    }
  }
}

// added by team politi

#wrapper {
	padding-top: 0;
}

.modal.modal-liferay {
	display: none;
}

.modal-title {
	white-space: normal;
}

// forgot password form
.form-login-centered {
	margin: auto;
	width: 50%;
}

body.controls-hidden .portlet-topper {
	display:none;
}

#portlet_com_liferay_login_web_portlet_LoginPortlet a.portlet-icon-back {
	display:none;
}

// hide back button Asset Publisher
.portlet-boundary_com_liferay_asset_publisher_web_portlet_AssetPublisherPortlet_ .asset-full-content.no-title .align-items-center.d-flex.mb-2 {
    display: none !important;
}

// view better widget controls
.portlet header.portlet-topper {
    z-index: 100;
}

.header-container .portlet header.portlet-topper {
	top: 20px;
}

// error page
.error-page-description {
	padding-top: 15px;
}

.error-page-go-to-home {
	margin-top: 50px;
}