.hero {

  &__img {
    line-height: 0;
  }

  &__title {
    font-size: 40px;
    line-height: 1.2;
    font-weight: $bold;
    color: $white;
    margin-bottom: 24px;

    @include media-breakpoint-between(md, lg) {
      font-size: 30px;
      margin-bottom: 16px;
    }

    @include media-breakpoint-down(md) {
      font-size: 26px;
    }
  }

  &__abstract {
    font-size: 18px;
    line-height: 1.2;
    font-weight: $regular;
    color: $white;

    @include media-breakpoint-between(md, lg) {
      font-size: 16px;
    }

    p {
      font-size: 18px;
      line-height: 1.2;
      font-weight: $regular;
      color: $white;
      margin-bottom: 0;

      @include media-breakpoint-between(md, lg) {
        font-size: 16px;
      }
    }
  }

  .cta-link {
    margin-top: 24px;
  }
}