.faccette {
  background-color: $white;
  border: solid 2px rgba($blue, 0.1);
  margin-bottom: 32px;

  &__header {
    font-size: 18px;
    line-height: 1.2;
    color: $black;
    font-weight: $bold;
  }

  .js-accordion__toggle {
    cursor: pointer;

    .ico-svg {
      min-width: 15px;
      max-width: 15px;
      max-height: 15px;
      min-height: 15px;
      transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
    }
  }
  
  .box-accordion {
    border-top: solid 2px rgba($blue, 0.1);
    padding: 30px 0;
    margin: 0 30px;
    
    &:first-child {
      border-top: none;
    }
    
    &.open {
      .js-accordion__toggle {
        .ico-svg {
          transform: rotate(180deg);
        }
      }
    }
    
  }
  
  &__label {
    font-size: 18px;
  }

  &__field {
    position: relative;
    font-size: 18px;
    line-height: 27px;
    color: $black;
    font-weight: $regular;
    padding-left: 15px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    
    &:hover, &.active {
      color: $blue-1;
      
      a, button {
        color: $blue-1;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $blue-1;
      }
    }

    a, button {
      color: $black;
      font-weight: $regular;
      text-decoration: none;
      background-color: transparent;

      @include media-breakpoint-only(lg) {
        font-size: 11px;
      }
    }
  }

  &__content {
    padding-top: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li:last-child {
        .faccette__field {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    &--badge {

      ul {
        li {
          display: inline-block;

          &:last-child {
            .faccette__field {
              margin-bottom: 8px;
            }
          }
        }
      }

      .faccette__field {
        padding-left: 0;

        &:hover, &.active {
          &::before {
            content: none;
          }
		  
		  a {
			color: $blue-1;
		  }
        }

        a {
          position: relative;
          display: inline-block;
          color: $orange;
          font-weight: $medium;
          text-decoration: none;
          margin-right: 8px;
          white-space: nowrap;

          &.active {
            color: $orange;
            border-color: $orange;
            padding-left: 20px;

            &::before {
              content: "";
              display: block;
              position: absolute;
              left: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid $blue-1;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }

  &__input {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 1;
    color: $orange;
    text-align: right;
    border: 0;
  }

  &__date {
    border-radius: 3px;
    border: solid 2px rgba($blue, 0.1);
    padding: 12px;
    color: $black;
    margin-bottom: 6px;

    .ico-svg {
      color: $orange;
      max-width: 16px;
      max-height: 16px;
      margin-right: 10px;
    }
  }

  &__select {
    select {
      font-size: 14px;
      padding: 7px 4px;
    }
  }

  &__viewall, &__viewless {
    display: block;
    margin-top: 30px;
    text-decoration: underline;
    text-transform: uppercase;
    color: $orange;
    background-color: transparent;
    outline: 0;
    
    &:hover {
      text-decoration: underline;
    }
  }
}