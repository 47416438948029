.unindustria-navigation {

  @include media-breakpoint-down(md) {
    display: none;
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    background-color: $blue-1;
    padding: 24px 0;

    &.open {
      display: block;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__item {
    display: block;
    list-style-type: none;
    font-size: 14px;
    line-height: 1;
    font-weight: $regular;
    color: $white;
    padding-top: 21px;
    margin-left: 30px;

    @include media-breakpoint-up(lg) {
      &:first-child {
        margin-left: 0;
      }

      &--active, &.open {
        .unindustria-navigation__link {
          border-color: $orange;
        }
      }
    }

    @include media-breakpoint-between(md, lg) {
      font-size: 12px;
      margin-left: 24px;
    }

    @include media-breakpoint-down(md) {
      border-bottom: solid 1px rgba($white, .3);
      padding-top: 6px;
      padding-bottom: 6px;
      margin: 0 24px;

      &--active {
        .unindustria-navigation__link {
          color: $orange;
        }
      }
    }

    &.open {
      
      @include media-breakpoint-down(md) {
        .unindustria-navigation__link {
          &::after {
            top: 14px;
            transform: rotate(-135deg);
          }
        }
      }

      .unindustria-navigation__megamenu {
        display: block;

        @include media-breakpoint-down(md) {
          max-height: 5000px;
        }
      }
    }
  }

  &__link {
    display: block;
    font-size: 14px;
    line-height: 1;
    font-weight: $regular;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: solid 2px transparent;
    transition: border-color 0.5s;
    padding: 9px 0;

    @include media-breakpoint-between(md, lg) {
      font-size: 12px;
    }

    @include media-breakpoint-up(lg) {
      &:not(.unindustria-navigation__link--home):hover,
      &:not(.unindustria-navigation__link--home):focus {
        color: $white;
        text-decoration: none;
      }

      &:hover {
        border-color: $orange;
      }

      &--home {
        border-bottom: 0;
        padding: 7px 0;

        .ico-svg {
          max-width: 14px;
          max-height: 12px;
        }
      }
    }
    
    @include media-breakpoint-down(md) {
      font-size: 16px;
      font-weight: $bold;
      border-bottom: 0;

      &:not(a) {
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 7px;
          right: 5px;
          border: solid $white;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
          padding: 5px;
        }
      }
    }
  }

  &__megamenu {
    display: none;
    position: absolute;
    left: 15px;
    right: 15px;
    z-index: 2;
    color: $white;
    padding: 70px 0;

    @include media-breakpoint-up(lg) {
      &:not(.unindustria-navigation__megamenu--content) {
        .unindustria-navigation__sublist {
          column-count: 3;
        }
      }
    }

    @include media-breakpoint-down(md) {
      position: static;
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
      padding: 0;
    }
  }

  &__sublist {
    min-width: 285px;
    padding-top: 24px;
    padding-left: 25px;
    padding-right: 40px;
    padding-bottom: 0;
    margin: 0;

    @include media-breakpoint-down(md) {
      padding-top: 12px;
    }
  }

  &__subitem {
    list-style-type: none;
    display: block;
    font-size: 18px;
    line-height: 1.2;
    font-weight: $bold;
    margin-bottom: 24px;

    @include media-breakpoint-between(md, lg) {
      font-size: 15px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 12px;
    }
  }

  &__sublink {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 1.2;
    font-weight: $bold;
    color: $white;
    text-decoration: none;
    padding-left: 32px;

    @include media-breakpoint-between(md, lg) {
      font-size: 15px;
    }

    @include media-breakpoint-down(md) {
      font-size: 15px;
      font-weight: $regular;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      transition: all 0.5s;
    }
  
    &:before {
      width: 20px;
      height: 1px;
      top: 50%;
      left: 0;
      background-color: $white;
      margin-top: -1px;
    }
  
    &:after {
      display: inline-block;
      border: solid $white;
      border-width: 0 2px 2px 0;
      top: 50%;
      left: 9px;
      transform: rotate(315deg);
      padding: 4px;
      margin-top: -5px;
    }

    &:hover, &:focus, &--active {
      color: $white;

      &:before {
        left: 5px;
        background-color: $orange;
      }

      &:after {
        left: 14px;
        border: solid $orange;
        border-width: 0 2px 2px 0;
      }
    }
  }

  &__title {
    font-size: 36px;
    line-height: 1.2;
    font-weight: $bold;
    color: $white;
    padding-left: 25px;
    padding-right: 40px;
    margin-bottom: 24px;

    @include media-breakpoint-between(md, lg) {
      font-size: 28px;
      margin-bottom: 16px;
    }
    
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 1.5;
    font-weight: $regular;
    color: $white;
    padding-left: 25px;
    padding-right: 40px;
    margin-bottom: 24px;
    
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__content {
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &-title {
      font-size: 36px;
      line-height: 1.2;
      font-weight: $bold;
      color: $white;
      margin-bottom: 24px;

      @include media-breakpoint-between(md, lg) {
        font-size: 28px;
        margin-bottom: 16px;
      }
    }

    &-abstract {
      font-size: 18px;
      line-height: 1.5;
      font-weight: $regular;
      color: $white;
      margin-bottom: 24px;

      @include media-breakpoint-between(md, lg) {
        font-size: 16px;
      }
      
      p {
        font-size: 18px;
        line-height: 1.5;
        font-weight: $regular;
        color: $white;

        @include media-breakpoint-between(md, lg) {
          font-size: 16px;
        }
      }
    }

    &-img {
      margin-bottom: 32px;
    }
  }
}