.unindustria-indice {

  &__title {
    font-size: 26px;
    line-height: 34px;
    font-weight: $bold;
    margin-bottom: 24px;
  }

  &__letters {
    margin-left: -8px;

    button {
      width: 32px;
      font-size: 21px;
      color: $orange;
      display: inline-block;
      text-align: center;
      text-transform: uppercase;
      font-weight: $bold;
      background-color: transparent;
      border: 0;
      padding: 4px 6px;
      margin: 0 3px 12px;

      &[disabled] {
        color: $black;
        font-weight: $regular;
      }

      &.active {
        color: $white;
        font-weight: $bold;
        background-color: $orange;
      }
    }
  }

  &__results {
    border-top: solid 1px $gray-2;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 24px;
  }

  &__list {
    column-count: 4;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(lg) {
      column-count: 3;
    }

    @include media-breakpoint-down(md) {
      column-count: 2;
    }

    @include media-breakpoint-down(sm) {
      column-count: 1;
    }
  }

  &__item {
    list-style-type: none;
    margin-bottom: 12px;
  }

  &__link {
    display: inline-block;
    text-decoration: none;
  }
}