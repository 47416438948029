.article-header {
  padding-bottom: 32px;

  &__title {
    font-size: 40px;
    line-height: 1.2;
    font-weight: $bold;
    color: $white;
    margin-bottom: 16px;

    @include media-breakpoint-between(md, lg) {
      font-size: 30px;
    }
  }

  &__abstract {
    font-size: 18px;
    line-height: 1.2;
    font-weight: $regular;
    color: $white;

    @include media-breakpoint-between(md, lg) {
      font-size: 16px;
    }

    p {
      font-size: 18px;
      line-height: 1.2;
      font-weight: $regular;
      color: $white;
      margin-bottom: 0;

      @include media-breakpoint-between(md, lg) {
        font-size: 16px;
      }
    }
  }

  &__img {
    line-height: 0;

    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }
  }
}