.box-useful {
  border-top: solid 2px $blue-2;
  border-bottom: solid 2px $blue-2;
  padding: 16px 0;
  margin-bottom: 16px;

  &__label {
    font-size: 21px;
    line-height: 25px;
    font-weight: $bold;
    color: $black;
    margin-right: 32px;
  }

  &__btn {
    display: inline-block;
    width: 46px;
    height: 46px;
    border: solid 1px $orange;
    border-radius: 50%;
    color: $orange;
    padding: 11px;
    margin-left: 16px;
  }
}