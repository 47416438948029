.unindustria-card {
  margin-bottom: 30px;

  &:hover, &:focus {
    .unindustria-card__content {
      border: solid 2px rgba($blue, 0.5);
    }
  }
  
  .unindustria-card-search-category-group{
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
    border: 1px solid;
    color: $blue-1;
    padding: 3px;
	margin-right: 10px;
  }

  &--equal-height {
    height: calc(100% - 30px);
  }

  &--search {
    .unindustria-card__category {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .unindustria-card__wrapper-img {
      width: 100%;
      min-width: 290px;
      max-width: 290px;
      padding-left: 24px;
      margin-bottom: 16px;
    }
  }

  &--appuntamento {
    .unindustria-card__category {
      margin-top: 0;
      margin-bottom: 20px;
    }
	
	.unindustria-card__category_group {
	  text-transform: uppercase;
	  font-size: 12px;
      line-height: 1;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 20px;
      border: 1px solid;
      color: $blue-1;
      padding: 3px;
	  margin-right: 10px
    }
  }

  &--pubblicazione, &--vision {
    .unindustria-card__title {
      text-transform: uppercase;
    }

    .unindustria-card__wrapper-img {
      width: 100%;
      min-width: 170px;
      max-width: 170px;
      padding-left: 24px;
    }
  }

  &__img {
    width: 100%;
  }

  &__content {
    position: relative;
    background-color: $white;
    border: solid 2px rgba($blue, 0.1);
    transition: border-color 0.5s;
    padding: 20px;
  }

  &__title {
    font-size: 21px;
    line-height: 32px;
    font-weight: $medium;
    color: $black;
    margin-bottom: 16px;

    @include media-breakpoint-between(md, lg) {
      font-size: 18px;
      line-height: 27px;
    }

    @include media-breakpoint-only(sm) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &__link {
    font-size: 21px;
    line-height: 32px;
    font-weight: $medium;
    color: $black;
    text-decoration: none;

    @include media-breakpoint-between(md, lg) {
      font-size: 18px;
      line-height: 27px;
    }

    @include media-breakpoint-only(sm) {
      font-size: 18px;
      line-height: 27px;
    }

    &:hover, &:focus {
      color: $blue;
    }
  }

  &__abstract {
    font-size: 16px;
    line-height: 24px;
    font-weight: $regular;
    margin-bottom: 16px;

    @include media-breakpoint-between(md, lg) {
      font-size: 14px;
      line-height: 22px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: $regular;
      margin-bottom: 0;

      @include media-breakpoint-between(md, lg) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  &__role {
    font-size: 16px;
    line-height: 24px;
    font-weight: $regular;
    margin-top: -16px;
    margin-bottom: 46px;
  }

  &__date {
    font-size: 16px;
    line-height: 24px;
    font-weight: $regular;
    margin-bottom: 10px;

    @include media-breakpoint-between(md, lg) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__calendar-date {
    border-left: solid 2px $orange;
    padding-left: 15px;
    margin-left: -22px;
    margin-bottom: 20px;

    .day {
      font-size: 60px;
      line-height: 1;
      font-weight: $light;
      color: $black;
      padding-right: 15px;
    }

    .month {
      font-size: 18px;
      line-height: 1;
      font-weight: $medium;
      color: $black;
      text-transform: uppercase;
    }

    .hour {
      font-size: 18px;
      line-height: 1;
      font-weight: $regular;
      color: $black;
      text-transform: uppercase;
      margin-top: 4px;
    }
  }

  &__category {
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    font-weight: $medium;
    color: $white;
    text-transform: uppercase;
    margin-top: -40px;
    margin-bottom: 8px;

    @include media-breakpoint-only(sm) {
      font-size: 12px;
    }

    span {
      display: inline-block;
      background-color: $blue;
      padding: 8px;
      margin-right: 4px;
      margin-bottom: 4px;

      @include media-breakpoint-between(md, lg) {
        padding: 8px 5px;
      }
    }
  }

  &__contenttype {
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    font-weight: $medium;
    color: $white;
    text-transform: uppercase;
    background-color: $blue-1;
    padding: 8px 11px;
    margin-right: 8px;
    margin-bottom: 20px;

    @include media-breakpoint-between(md, lg) {
      padding: 8px 5px;
    }

    @include media-breakpoint-only(sm) {
      font-size: 12px;
    }
  }

  &__tag {
    font-size: 16px;
    line-height: 1.2;
    text-decoration: none;

    @include media-breakpoint-between(md, lg) {
      font-size: 14px;
    }
  }

  &__from {
    font-size: 14px;
    line-height: 1.2;
    color: $black;
    margin-bottom: 24px;
  }

  &__album {
    font-size: 16px;
    line-height: 1.2;
    font-weight: $regular;
    color: $blue;
  }

  &__calendar {
    font-size: 14px;
    line-height: 21px;
    font-weight: $light;
    color: $black;

    .remove {
      display: none;
    }

    .ico-svg {
      max-width: 18px;
      max-height: 21px;
      color: $orange;
      margin-right: 8px;
    }

    &--active {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
      .ico-svg {
        color: $blue;
      }
    }
  }

  &__customer {
    font-size: 16px;
    line-height: 19px;
    font-weight: $regular;
    color: $blue;
    margin-bottom: 4px;
  }

  &__settore {
    font-size: 14px;
    line-height: 19px;
    font-weight: $regular;
    color: $black;
  }
}