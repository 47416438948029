.checkbox {
  display: block;
  position: relative;
  user-select: none;
  
  padding-left: 35px;
  margin-bottom: 16px;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  &__input {
    position: absolute;
    opacity: 0;
    
    &:checked {
      ~ .checkbox__mark {
        background-color: $white;
        border-color: $black;
        
        &::after {
          display: block;
        }
      }
    }
  }
  
  &__mark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $black;
    border-radius: 0;
    
    &::after {
      display: none;
      content: "";
      position: absolute;
      top: 2px;
      right: 6px;
      width: 6px;
      height: 10px;
      border: solid $orange;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
    }
  }
}