.nav-child {
  @include media-breakpoint-up(lg) {
    background-color: $blue-1;
    padding: 12px 0;
    margin-bottom: 30px;

    &--has-dots {
      .nav-child__wrapper {
        position: relative;
        padding-right: 60px;
      }
      .nav-child__item {
        padding-right: 12px;
        margin-left: 0;
      }
    }

    &.open {
      .nav-child__others {
        display: block;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.open {
      .nav-child__list {
        display: block;
      }
    }

    &__wrapper {
      margin-bottom: 24px;
    }
  }

  &__list {
    font-size: 18px;
    line-height: 1.2;
    color: $white;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(md) {
      display: none;
      color: $blue;
      background-color: $white;
      box-shadow: 0 4px 4px 0 rgba($blue-3, 0.1), 0 8px 16px 0 rgba($blue-3, 0.25);
      padding: 20px;
    }
  }

  &__item {
    list-style-type: none;
    font-size: 18px;
    line-height: 1.2;
    color: $white;

    @include media-breakpoint-up(lg) {
      margin-left: 32px;
      
      &:first-child {
        margin-left: 0;
      }
    }

    @include media-breakpoint-down(md) {
      color: $blue;
    }

    &--active {
      .nav-child__link {
        font-weight: $bold;

        &:hover {
          color: $white;

          @include media-breakpoint-down(md) {
            color: $blue;
          }
        }
      }
    }
  }

  &__link {
    font-size: 18px;
    line-height: 1.2;
    color: $white;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      display: inline-block;
      color: $blue;
      margin: 8px 0;
    }

    &:hover {
      color: $orange;
      text-decoration: none;
    }
  }

  &__btn {
    position: absolute;
    top: -12px;
    right: 0;
    bottom: -12px;
    z-index: 4;
    width: 30px;
    background-color: transparent;
    color: $white;

    @include media-breakpoint-down(md) {
      position: static;
      width: auto;
      background-color: $blue-1;
      padding-left: 12px;
      padding-right: 8px;
      margin-bottom: 16px;
    }

    .ico-svg {
      color: $white;
    }
  }

  &__others {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 4;
    width: 230px;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      right: 5px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $white;
      margin-left: -5px;
    }

    > div {
      min-height: 170px;
      background-color: $white;
      box-shadow: 0 4px 4px 0 rgba($blue-3, 0.1), 0 8px 16px 0 rgba($blue-3, 0.25);
      padding: 30px 20px;
      margin-top: 15px;
    }
  }

  &__others-link {
    display: block;
    font-size: 18px;
    line-height: 1.2;
    color: $blue-1;
    text-decoration: none;
    margin-bottom: 12px;
    
    &:hover, &:focus {
      font-size: 18px;
      line-height: 1.2;
      color: $orange;
      text-decoration: none;
    }

    &--active {
      font-weight: $bold;
    }
  }
}