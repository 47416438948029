.bookmark {
  &__btn {
    color: $orange;
    cursor: pointer;

    .remove {
      display: none;
    }

    .ico-svg {
      min-width: 15px;
      max-width: 15px;
      min-height: 20px;
      max-height: 20px;
    }

    &--active {
      color: $blue;

      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }
  }
  
  &__info {
    width: 220px;
    position: absolute;
    left: -180px;
    top: -70px;
    z-index: 2;
    font-size: 14px;
    line-height: 21px;
    color: $black;
    background-color: $white;
    box-shadow: 0 0 20px rgba($black, .1);
    padding: 8px 12px;
  }
}